import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import styled from 'styled-components';
import "@babel/polyfill";

import Layout from "../layouts";
import SectionHero from "../components/section-hero";
import Head from "../components/head";
import Articles from '../components/articles';
import SidebarForm from '../components/sidebar-form';

import '../css/single-faq.css';

import YoastSeoParts from '../fragments/seo';

class SingleFaqTemplate extends Component {
  render() {
    const faq = this.props.data.wpFaq;
    const posts = this.props.data.allWpPost
    const { seo, cptFaqs } = faq;

    let articleTitle = cptFaqs.articleTitle ? cptFaqs.articleTitle : 'Our Latest Articles';

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />

        <SectionHero section="resource" title={faq.title}/>


        <div className="container baseContainerWrapper single-faq-container">
          <div className="baseContainer">
            <div className="grid smallGrid">
              <div className="col-md-8 baseContainerLeft">
                <h2 style={{ fontSize: '46px' }}>{faq.title}</h2>
                <div className="faq-content" dangerouslySetInnerHTML={{ __html: faq.content }} />
              </div>
              <div className="col-md-4 baseContainerRight">
                <SidebarForm />
              </div>

              <div className="col-md-12">
                <Articles title={articleTitle} posts={posts.nodes} link={cptFaqs?.articles?.wpURI} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default SingleFaqTemplate

SingleFaqTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!, $articlesCategoryId: String = "//") {
    wpFaq(id: {eq: $id}) {
      title
      content
      uri
      id
      seo {
        ...seoParts
      }
      cptFaqs {
        articleTitle
        articles {
          wpURI
        }
      }
    }

    allWpPost (
      filter: {categories: {nodes: {elemMatch: {id: {regex: $articlesCategoryId}}}}}
      sort: {order: DESC, fields: date}
      limit: 10
    ) {
      nodes {
        id
        title
        link
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`